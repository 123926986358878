


























































































































































































































.justifypo{
  display: flex;
justify-content: space-between;

.justify{
 height: 300px;
background: #fff;
padding: 16px;
border-radius:10px ;

}
.icon{
  height: 250px;
  overflow: auto;
  i{
    float: left;
    margin-top:5px ;
    padding-right:2px ;
    color: red;
  }
}
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mdro7 {
  display: flex;
  justify-content: center;

  margin: 0 0 15px 0;
}
.mdro6 {
  margin: 0 0 15px 32px;
}
.mr5 {
  margin-right: 15px;
}
